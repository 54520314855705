import React, { useContext, useMemo, useState } from 'react';
import WishlistContext from '../../context/WishlistProvider';

import { Center, Spinner } from '@chakra-ui/react';
import { useForm } from '@mantine/form';
import { useQuery } from '@tanstack/react-query';
import Button from '../../components/atoms/Button/Button';
import ConfirmDialog from '../../components/atoms/ConfirmDialog/ConfirmDialog';
import Icon from '../../components/atoms/Icon/Icon';
import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import * as pcStyles from './wishlists.module.scss';
import * as mpsStyles from './wishlists.mps.module.scss';
import { Link } from 'gatsby';
import { storeStyle } from '../../common/util';

const styles = storeStyle({mpsStyles, pcStyles});
const AccountWishlists = () => {
  const wishlistContext = useContext(WishlistContext);
  const [saving, setSaving] = useState(false);
  const [showNewWishlist, setShowNewWishlist] = useState(false);
  const [showUpdateWishlist, setShowUpdateWishlist] = useState(false);

  const form = useForm({
    initialValues: {
      wishlist_name: '',
      selectedWishlist: null
    },
    validate: {
      wishlist_name: value => {
        if (!value) {
          return 'Wishlist name is required';
        }
        if (!checkNameDuplicate(value)) {
          return 'Wishlist already exists';
        }
        return null;
      }
    }
  });
  const { getAccountWishlists, getWishlistItems, checkNameDuplicate } =
    wishlistContext || {};

  const { data: wishlists, isLoading, refetch } = useQuery(
    ['wishlists'],
    async () => await getAccountWishlists()
  );

  const { data: wishlistsProducts, isLoading: fetchingProducts } = useQuery(
    ['wishlists_products', form.values.selectedWishlist?.items],
    async () => await getWishlistItems(form.values.selectedWishlist.items, 300),
    {
      enabled:
        showUpdateWishlist &&
        !!form.values.selectedWishlist &&
        form.values.selectedWishlist.items.length > 0
    }
  );

  const addNewWishlist = e => {
    e.preventDefault();
    const validate = form.validate();

    if (validate.hasErrors) {
      return;
    }

    setSaving(true);
    wishlistContext
      .saveWishlist(form.values.wishlist_name)
      .then(({ status }) => {
        if (status === 201) {
          setShowNewWishlist(false);
          refetch();
          form.reset();
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const removeWishlist = wishlistId => {
    setSaving(true);
    wishlistContext
      .removeWishlist(wishlistId)
      .then(() => {
        refetch();
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const updateWishlist = wishlist => {
    setShowUpdateWishlist(true);
    form.setFieldValue('selectedWishlist', wishlist);
  };

  const removeWishlistItem = (productId, variantId) => {
    if (form.values.selectedWishlist) {
      const wishlistItemId = form.values.selectedWishlist.items.find(
        item => item.product_id === productId && item.variant_id === variantId
      ).id;

      if (wishlistItemId) {
        wishlistContext
          .removeFromAccountWishlist(
            form.values.selectedWishlist.id,
            [],
            wishlistItemId,
            null,
            null
          )
          .then(status => {
            if (status === 204) {
              const newItems = form.values.selectedWishlist.items.filter(
                item =>
                  !(
                    item.product_id === productId &&
                    item.variant_id === variantId
                  )
              );

              form.setFieldValue('selectedWishlist', {
                ...form.values.selectedWishlist,
                items: newItems
              });

              if (newItems.length === 0) {
                removeWishlist(form.values.selectedWishlist.id);
                setShowUpdateWishlist(false);
              }
            }
          });
      }
    }
  };

  const parseWishlistItems = useMemo(() => {
    if (wishlistsProducts?.length > 0 && form.values.selectedWishlist?.items) {
      const sanitizedProducts = form.values.selectedWishlist?.items
        .map(item => {
          const findProduct = wishlistsProducts.find(
            product => product.node.entityId === item.product_id
          );

          if (findProduct) {
            const findVariant = findProduct.node.variants?.edges?.find(
              variant => variant?.node?.entityId === item.variant_id
            )?.node?.options?.edges?.[0]?.node?.values?.edges?.[0]?.node?.label;
            return {
              ...findProduct?.node,
              variantId: item.variant_id,
              variantName: findVariant
            };
          }
        })
        .filter(Boolean);
      return sanitizedProducts;
    }

    return [];
  }, [wishlistsProducts, form.values.selectedWishlist]);
 
  return (
    <>
      <h1 className={`center-title ${styles.pageTitle}`}>
        {showUpdateWishlist ? form.values.selectedWishlist.name : 'Wishlists'}
      </h1>
      {isLoading && (
        <Center py={8}>
          <Spinner size={'xl'} color="teal" />
        </Center>
      )}

      {/* Add new wishlist  */}
      <div className={`formField ${showNewWishlist ? 'show' : 'hidden'}`}>
        <label htmlFor="wishlist_name">Wishlist name</label>
        <input
          name="wishlist_name"
          id="wishlist_name"
          type="text"
          {...form.getInputProps('wishlist_name')}
        />
        <div className={`${styles.wishlistErrorContainer} ${styles.show}`}>
          <span className={styles.wishlistError}>
            {form.errors.wishlist_name}
          </span>
        </div>
        <div className="row mt-6">
          <Button
            loading={saving}
            disabled={saving}
            type="span"
            level="tertiary"
            onClick={addNewWishlist}
          >
            Save wishlist
          </Button>
          <Button
            type="span"
            level="ghost"
            onClick={() => setShowNewWishlist(false)}
            className="ml-4"
          >
            Cancel
          </Button>
        </div>
      </div>

      {/* Render wishlist items */}
      {!showNewWishlist && !showUpdateWishlist && (
        <div className={styles.container}>
          {wishlists?.data?.length > 0 &&
            wishlists?.data?.map(wishlist => (
              <div className={styles.wishlistCard}>
                <div className="content">
                  <p className="title">{wishlist.name}</p>
                  <p className="count">
                    {wishlist.items.length}{' '}
                    {wishlist.items.length > 1 ? 'items' : 'item'}
                  </p>
                </div>
                <div className="controls">
                  <span
                    role="presentation"
                    data-button
                    className="icon-wrap"
                    onClick={() => updateWishlist(wishlist)}
                  >
                    <Icon symbol={'pencil'} />
                  </span>
                  <ConfirmDialog
                    title="Are you sure?"
                    message="Are you sure you want to remove this wishlist? It will not be recoverable after you proceed."
                    onOk={() => removeWishlist(wishlist.id)}
                  >
                    <span data-button className="icon-wrap">
                      <Icon symbol={'trash'} />
                    </span>
                  </ConfirmDialog>
                </div>
              </div>
            ))}
          <div
            className={`${styles.rootEmpty}`}
            role="button"
            tabIndex={-1}
            onClick={() => setShowNewWishlist(true)}
          >
            <div className={styles.emptyText}>
              <Icon symbol={'plus'} />
              <p>New wishlist</p>
            </div>
          </div>
        </div>
      )}

      {/* Show wishlist items */}
      {showUpdateWishlist && (
        <>
          {fetchingProducts && (
            <Center py={8}>
              <Spinner size={'xl'} color="teal" />
            </Center>
          )}
          {wishlistsProducts?.length > 0 && (
            <table className={styles.wishlistProducts}>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {parseWishlistItems?.map(node => {
                  const prescription = node?.productOptions?.edges?.find(
                    ({ node }) => node?.displayName === 'Prescription'
                  )
                    ? true
                    : false;
                  return (
                    <tr>
                      <td>
                        <Link to={node.path} className="product-details">
                          <img
                            src={node.defaultImage?.url ?? '/404.svg'}
                            alt={node.name}
                          />
                          <div>
                            <p className="name">{node.name}</p>
                            <p className="name">{node?.variantName}</p>

                            {prescription && (
                              <p className="prescription">
                                <Icon symbol="prescription" /> Requires
                                prescription
                              </p>
                            )}
                          </div>
                        </Link>
                      </td>
                      <td>
                        <p className="price">${node.prices.price.value}</p>
                      </td>
                      <td>
                        <span
                          onClick={() =>
                            removeWishlistItem(node.entityId, node.variantId)
                          }
                          data-button
                          className="icon-wrap"
                        >
                          <Icon symbol={'trash-alt'} />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};

const AccountWishlistsOutput = () => {
  return (
    <AccountPageWrapper metaTitle="Account - Wishlists" title="Wishlists">
      <AccountWishlists />
    </AccountPageWrapper>
  );
};
export default AccountWishlistsOutput;
